import './Title.css';

function Title() {
		return (
			<div className="Title">
				<div id="title">Maya Angelou Recites...</div>
				<div id="song_title">In The Party</div>
				<div id="artist">By Flo Milli</div>
			</div>
		);
	}
	
	export default Title;
	