import './App.css';
import logo from './the_wild.png'
import StoryPane from './components/StoryPane';

function App() {
	return (
		<div className="App">
			<div id="desktopBanner">the Wild. is currently a mobile only experience.</div>
			<StoryPane />
			<div id="desktopMessage">
				<img id="theWildLogo" src={logo} alt="A wild gorilla logo in red."/>
			</div>
		</div>
	);
}

export default App;
