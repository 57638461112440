import './TippingCard.css'

function TippingCard() {
		return (
			<div className="TippingCard">
				<div className="Card">
					<a class="button" href="https://cash.app/justalexbruh">Tip with Cash App!</a>
					<a class="button" href="https://venmo.com/justalexbruh">Tip with Venmo!</a>
					<a class="button" href="https://paypal.me/alexjeanb">Tip with Paypal!</a>
				</div>
			</div>
		);
	}
	
	export default TippingCard;
	