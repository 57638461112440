import logo from '../mother_maya.png'
import './Logo.css'

function Logo() {
		return (
			<div className="Logo">
				<img id="mayaLogo" src={logo} alt="Maya Angelou and Alex" width="100" height="100"/>
			</div>
		);
	}
	
	export default Logo;
	