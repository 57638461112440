import './StoryPane.css'
import TippingCard from './TippingCard';
import Title from './Title';
import Logo from './Logo';

function StoryPane() {
	return (
		<div className="StoryPane">
			<Title />
			<TippingCard />
			<Logo />
		</div>
	);
}

export default StoryPane;
